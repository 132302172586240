import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { IoDocumentTextOutline } from '@react-icons/all-files/io5/IoDocumentTextOutline';
import { IoCheckmarkDoneSharp } from '@react-icons/all-files/io5/IoCheckmarkDoneSharp';
import { BiLoaderCircle } from '@react-icons/all-files/bi/BiLoaderCircle';
import { MdKeyboardArrowRight } from '@react-icons/all-files/md/MdKeyboardArrowRight';
import * as classes from './vendoor-banner.module.scss';

const VendoorBanner = () => {
  return (
    <div className={classes.container}>
      <div className={classes.image}>
        <StaticImage
          src="../../images/vendoor-mockup.png"
          alt="Plataforma Vendoor"
          className="rounded-lg"
        />
      </div>
      <StaticImage src="../../images/logo_vendoor_white.png" alt="Vendoor" />
      <div className="mt-auto flex flex-col gap-8">
        <p className="flex text-white font-bold gap-3">
          <IoDocumentTextOutline color="#E1F317" size={30} />
          Crie seu currículo de forma rápida.
        </p>
        <p className="flex text-white font-bold gap-3">
          <IoCheckmarkDoneSharp color="#E1F317" size={30} />
          Se candidate a quantas vagas quiser.
        </p>
        <p className="flex text-white font-bold gap-3">
          <BiLoaderCircle color="#E1F317" size={30} />
          Acompanhe seus processos em andamento.
        </p>
        <a
          href={`https://dashboard.vendoor.me/`}
          target={`_blank`}
          className="bg-[#E1F317] rounded-full shadow-[0_0_25px_0px_rgba(113,128,150,0.5)] py-2 px-4 h-[40px] font-semibold text-xs inline-flex justify-between items-center"
        >
          Ir para a plataforma
          <MdKeyboardArrowRight size={16} />
        </a>
      </div>
    </div>
  );
};

export default VendoorBanner;
