import React from 'react';
import * as classes from './medium-blog-card.module.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import clsx from 'clsx';

interface MinimalBlogCardProps {
  title: string;
  coverImage: {
    childImageSharp: {
      gatsbyImageData: any;
    };
  };
  categories: string[];
  description?: string;
  date?: string;
  largeMobile?: boolean;
  slug?: string;
}

export function MediumBlogCard(props: MinimalBlogCardProps) {
  const {
    title,
    coverImage,
    categories,
    date,
    description,
    largeMobile,
    slug,
  } = props;

  let image = getImage(coverImage);

  return (
    <div
      className={clsx({
        [classes.container]: true,
        [classes.largeMobile]: largeMobile,
      })}
    >
      <div className={classes.imageContainer}>
        {image && (
          <GatsbyImage
            image={image}
            className={clsx({
              [classes.image]: true,
              [classes.largeMobile]: largeMobile,
            })}
            alt={`Imagem de capa do post ${title}`}
          />
        )}
      </div>

      <div className={classes.content}>
        <div
          className={clsx({
            [classes.categories]: true,
            [classes.largeMobile]: largeMobile,
          })}
        >
          {categories.map(category => (
            <div
              className={clsx({
                [classes.category]: true,
                [classes.largeMobile]: largeMobile,
              })}
              key={category}
            >
              {category}
            </div>
          ))}

          <div
            className={clsx({
              [classes.line]: true,
              [classes.largeMobile]: largeMobile,
            })}
          />
        </div>

        <h2
          className={clsx({
            [classes.title]: true,
            [classes.largeMobile]: largeMobile,
          })}
        >
          {title}
        </h2>

        {description && (
          <p
            className={clsx({
              [classes.description]: true,
              [classes.largeMobile]: largeMobile,
            })}
          >
            {description}
          </p>
        )}

        {date && (
          <p
            className={clsx({
              [classes.date]: true,
              [classes.largeMobile]: largeMobile,
            })}
          >
            {new Date(date).toLocaleDateString('pt-br', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </p>
        )}

        <a href={`/blog/${slug}/`}>Ler artigo</a>
      </div>
    </div>
  );
}
