// extracted by mini-css-extract-plugin
export var categories = "medium-blog-card-module--categories--fc2e7";
export var category = "medium-blog-card-module--category--4cfca";
export var container = "medium-blog-card-module--container--c011c";
export var content = "medium-blog-card-module--content--b1e05";
export var date = "medium-blog-card-module--date--2bd17";
export var description = "medium-blog-card-module--description--2fd9f";
export var image = "medium-blog-card-module--image--bce2c";
export var imageContainer = "medium-blog-card-module--imageContainer--bf428";
export var largeMobile = "medium-blog-card-module--largeMobile--defcc";
export var line = "medium-blog-card-module--line--a9ba4";
export var title = "medium-blog-card-module--title--0694b";