import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import * as classes from './blog-post.module.scss';

interface BlogPostProps {
  data: {
    title: string;
    slug: string;
    date: string;
    tags: string[];
    coverImage: {
      childImageSharp: {
        gatsbyImageData: any;
      };
    };
  };
  children: React.ReactNode;
}

const BlogPost = (props: BlogPostProps) => {
  const { title, tags, date, coverImage } = props.data;
  let image = getImage(coverImage);

  return (
    <div className={classes.container}>
      <header>
        <div className={classes.imgWrapper}>
          {image && (
            <GatsbyImage image={image} alt={title} className={classes.image} />
          )}
        </div>

        <div>
          <h1 className={classes.title}>{title}</h1>
          <p className={classes.date}>
            {new Date(date).toLocaleDateString('pt-br', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </p>
        </div>
      </header>
      <div className={classes.content}>{props.children}</div>
      {tags && (
        <div className={classes.tagsWrapper}>
          {tags.map(tag => (
            <div className={classes.tag} key={tag}>
              <span>{tag}</span>
            </div>
          ))}
          <div className={classes.fill}></div>
        </div>
      )}
    </div>
  );
};

export default BlogPost;
