import React from 'react';
import * as classes from './blog-card-list.module.scss';
import clsx from 'clsx';

interface BlogCardListProps {
  children: React.ReactNode;
  title?: string;
  background?: boolean;
  withoutTopMargin?: boolean;
}

export function BlogCardList(props: BlogCardListProps) {
  const { title, children, background, withoutTopMargin } = props;

  return (
    <div className={classes.container}>
      {title && (
        <h2
          className={clsx({
            [classes.title]: true,
            [classes.withoutTopMargin]: withoutTopMargin,
          })}
        >
          {title}
        </h2>
      )}

      <div
        className={clsx({
          [classes.cards]: true,
          [classes.background]: background,
        })}
      >
        {children}
      </div>
    </div>
  );
}
